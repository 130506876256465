.extraSmall {
  width: 21px;
}

.small {
  width: 25px;
}

.small-medium {
  width: 45px;
}

.medium {
  width: 60px;
}

.large {
  width: 100px;
}

.fullWidth {
  width: 100%;
  height: 100%;
}

.large-shrink-mobile {
  width: 100px;
}

@media (max-height: 750px) {
  .large-shrink-mobile {
    width: 60px;
  }
}
