body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  user-select: none;
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

a {
  cursor: pointer;
}

a:link {
  color: #ff5864;
  text-decoration: none;
}

a:visited {
  color: #ff5864;
  text-decoration: none;
}

a:hover {
  color: #ff5864;
  text-decoration: underline;
}

a:active {
  color: #ff5864;
  text-decoration: none;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
